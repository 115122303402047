import React from 'react';
import { VStack, Divider, Text, Flex, Box, Button, Image, HStack } from '@chakra-ui/react';
import { useNavigate } from 'react-router-dom';
import EmbeddedPopularCollections from './EmbeddedPopularCollections';
import ErrorBoundary from './ErrorBoundary';

const Hero = () => {
  const navigate = useNavigate();

  return (
    <Flex direction="column" alignItems="center" justifyContent="center" minH="120vh" mt={16}>
      <VStack spacing={9}>
        <Box>
          <Flex justifyContent="center" alignItems="center">
            <Text
              fontSize={['3xl', '4xl', '7xl']}
              fontWeight="bold"
              letterSpacing="-0.02em"
              textShadow="sm"
              lineHeight={['initial', '5rem']}
              /* my="1px" */
              as="h1"
              bgGradient="linear(to-tr, blue.600, red.300)"
              bgClip="text"
            >
              Trade,
            </Text>
            <Text
              fontSize={['3xl', '4xl', '7xl']}
              fontWeight="bold"
              letterSpacing="-0.02em"
              textShadow="sm"
              lineHeight={['initial', '5rem']}
              /* my="1px" */
              as="h1"
              bgGradient="linear(to-tr, red.300, teal.300)"
              bgClip="text"
            >
              Swap,
            </Text>
            <Text
              fontSize={['3xl', '4xl', '7xl']}
              fontWeight="bold"
              letterSpacing="-0.02em"
              textShadow="sm"
              lineHeight={['initial', '2rem', '5rem']}
              /* my="1px" */
              as="h1"
              bgGradient="linear(to-tr, teal.300, purple.500)"
              bgClip="text"
            >
              Collect
            </Text>
          </Flex>
          <Text
            textAlign="center"
            fontSize={['md', 'xl', '4xl']}
            fontWeight="bold"
            letterSpacing="-0.02em"
            textShadow="sm"
            lineHeight={['initial', '1rem', '4rem']}
            justifyContent="center"
            as="h2"
          >
            like never before
          </Text>
        </Box>

        {/* View Offers Button */}
        <Button
          size="lg"
          fontWeight="bold"
          borderRadius={'xl'}
          px={6}
          /* bgGradient="linear(to-tr, blue.600, purple.500)" */
          color="white"
          /* _hover={{
            bgGradient: 'linear(to-tr, blue.700, purple.600)',
          }} */
          onClick={() => navigate('/feed')}
        >
          View Offers
        </Button>

        <Box maxWidth="900px" w="100%" mt={32}>
          <Flex direction={['column', 'column', 'row']} alignItems="center" justifyContent="space-between">
            <Image src="/pxswaplanding2.png" alt="NFT Swap" maxWidth={['100%', '96%', '60%']} mb={[6, 6, 0]} />
            <VStack
              spacing={'1'}
              align={['center', 'center', 'start']}
              maxWidth={['100%', '100%', '40%']}
              textAlign={['center', 'center', 'left']}
            >
              <Text fontSize="2xl" fontWeight="bold" bgGradient="linear(to-tr, blue.600, purple.500)" bgClip="text">
                Trade Confidently
              </Text>
              {/*               <Text fontSize={['sm', 'md', 'lg']} fontWeight="semibold">
                No Limits. No Hassles.
              </Text>
              <Text fontSize={['sm', 'md', 'lg']} fontWeight="semibold">
                Seamless swaps. Full control.
              </Text> */}
              <Text fontSize={['sm', 'md', 'lg']} fontWeight="semibold">
                No Limits. Full control.
              </Text>
            </VStack>
          </Flex>
        </Box>

        <Box w="100%" /* maxWidth="900px" */>
          <ErrorBoundary>
            <EmbeddedPopularCollections />
          </ErrorBoundary>
        </Box>
        <VStack spacing={3} p={6} /* mt="9"  */ borderRadius="lg" boxShadow="lg" maxWidth="600px" w="100%">
          {/* <Text as="h3" fontSize={['xl', '2xl', '3xl']} fontWeight="bold">Offers Feed</Text> */}
          {/* <Divider /> */}
          {/*           <Divider /> */}
        </VStack>
      </VStack>
    </Flex>
  );
};

export default Hero;
