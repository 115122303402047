import {
  Flex,
  Heading,
  Button,
  Image,
  useBreakpointValue,
  IconButton,
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
} from '@chakra-ui/react';
import { Link } from 'react-router-dom';
import { ConnectButton } from '@rainbow-me/rainbowkit';
import { GiHamburgerMenu } from 'react-icons/gi';

const Header = () => {
  const isMobile = useBreakpointValue({ base: true, md: false });

  return (
    <Flex
      as="header"
      align="center"
      justify="space-between"
      p={3}
      px={{ base: 4, md: 8, lg: 24 }}
      position="sticky"
      top="0"
      backgroundColor="rgba(0, 0, 0, 0.6)"
      backdropFilter="blur(4px)"
      zIndex="1"
    >
      <Link to="/">
        <Flex align="center" cursor="pointer" gap={'1px'}>
          <Image src="logo.png" boxSize={{ base: '52px', md: '52px' }} />
          {!isMobile && (
            <Heading size={{ base: 'sm', md: 'md' }} fontWeight={'extrabold'}>
              pxswap
            </Heading>
          )}
        </Flex>
      </Link>
      {isMobile ? (
        <Flex align="center">
          <ConnectButton chainStatus="icon" accountStatus="avatar" showBalance={false} />
          <Menu>
            <MenuButton as={IconButton} aria-label="Options" icon={<GiHamburgerMenu />} bg="black" ml={2} />
            <MenuList bg="black">
              <MenuItem as={Link} to="/feed" bg="black" fontWeight={'extrabold'} size="md">
                Feed
              </MenuItem>
              <MenuItem as={Link} to="/inventory" bg="black" fontWeight={'extrabold'} size="md">
                Inventory
              </MenuItem>
            </MenuList>
          </Menu>
        </Flex>
      ) : (
        <Flex align="flex-end" gap={'12px'}>
          <Link to="/feed">
            <Button
              fontWeight={'extrabold'}
              backdropFilter="blur(10px)"
              backgroundColor="rgba(0, 0, 0, 0)"
              borderRadius={'xl'}
              size="md"
            >
              Feed
            </Button>
          </Link>
          <Link to="/inventory">
            <Button
              fontWeight={'extrabold'}
              backdropFilter="blur(10px)"
              backgroundColor="rgba(0, 0, 0, 0)"
              borderRadius={'xl'}
              size="md"
            >
              Inventory
            </Button>
          </Link>
          <ConnectButton
            chainStatus="icon"
            accountStatus="address"
            label="Sign in"
            showBalance={{
              smallScreen: false,
              largeScreen: false,
            }}
          />
        </Flex>
      )}
    </Flex>
  );
};

export default Header;
